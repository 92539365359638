import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {SubDomainStore} from "../../../../home-layout/common/store/sub-domain-store.service";
import {HttpClientWrapperService} from "../../../../home-layout/common/service/http-client-wrapper.service";
import {SearchOutDto} from "../../../../home-layout/common/model/search.model";
import {ROUTE} from "../../../../home-layout/common/constant/routing.constant";
import {DomainUserOutDto, JwtTokenDto, USER_TYPE, UserOutDto} from "../../../../home-layout/common/model/user.model";

@Injectable({
    providedIn: 'root'
})
export class SupportUserService {
    private supportUserUrl = `/supportUsers`;
    private switchAccountUrl = `/switchAccount`;
    private searchEndUsersUrl = `/searchEndUsers`;
    private loginUrl = `/login`;
    private apiBaseUrl: string;

    private readonly requestOptions = {
        params: new HttpParams(),
        headers: new HttpHeaders()
    };

    constructor(private subDomainStore: SubDomainStore,
                private readonly httpClientWrapperService: HttpClientWrapperService,
                private readonly httpClient: HttpClient) {
        this.subscribeApiUrl();
    }

    login(supportLoginInDto: SupportLoginInDto): Observable<SupportLoginOutDto> {
        return this.httpClientWrapperService.post(`${this.supportUserUrl}${this.loginUrl}`, supportLoginInDto);
    }

    switchAccount(supportLoginInDto: SupportLoginInDto): Observable<SupportLoginOutDto> {
        this.requestOptions.headers = this.attachJwtTokenToHeaders();
        return this.httpClient.post<SupportLoginOutDto>(`${this.apiBaseUrl}${this.supportUserUrl}${this.switchAccountUrl}`, supportLoginInDto, this.requestOptions);
    }

    searchEndUsers(searchText: String, pageIndex: number, pageSize: number): Observable<SearchOutDto<EndUserOutDto>> {
        this.requestOptions.headers = this.attachJwtTokenToHeaders();
        return this.httpClient.post<SearchOutDto<EndUserOutDto>>(`${this.apiBaseUrl}${this.supportUserUrl}${this.searchEndUsersUrl}?searchKeyword=${searchText}&pageIndex=${pageIndex}&pageSize=${pageSize}`, {}, this.requestOptions);
    }

    isSupportUserLoggedIn(): boolean {
        const isSupportUser = localStorage.getItem(ROUTE.SUPPORT);
        return isSupportUser !== null && isSupportUser === 'true';
    }

    saveSupportLoginFlag(enableSupportLogin: boolean): void {
        localStorage.setItem(ROUTE.SUPPORT, String(enableSupportLogin));
    }

    clearSupportLoginFlag() {
        localStorage.removeItem(ROUTE.SUPPORT);
    }

    private attachJwtTokenToHeaders(): HttpHeaders {
        const token = localStorage.getItem(ROUTE.TOKEN);
        if (token) {
            return new HttpHeaders().append('Authorization', token).append('jwtToken', token.replace('Bearer ', ''));
        }
        return new HttpHeaders();
    }

    private subscribeApiUrl(): void {
        this.subDomainStore.apiUrl.subscribe(apiUrl => {
            this.apiBaseUrl = apiUrl.toString();
        });
    }
}

export class EndUserOutDto {
    fullName: string;
    emailAddress: string;
    userType: USER_TYPE;
    profileUrl: string;
    logoUrl: string;
}

export class SupportLoginInDto {
    username: string;
    password: string;
    endUsername: string;
}

export class SupportLoginOutDto {
    public domainUserOutDto: DomainUserOutDto;
    public jwtTokenDto?: JwtTokenDto;
    public userOutDto?: UserOutDto;
}
