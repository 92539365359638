import {ContactPersonOutDto} from "./contact-person.model";
import {CompanyOutDto} from "../../setting/common/service/company.service";

export class UserCommonDto {
    username: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    contactNumber: string;
    contactNumberType?: string; // TODO : USE CONTACT_NUMBER_TYPE HERE
    userRole: string;
    enabled: boolean;
    userType: USER_TYPE;
}

export class UserInDto extends UserCommonDto {
    password: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    userProfileInDto: UserProfileInDto;
}

export class UserOutDto extends UserCommonDto {
    public id: string;
    userProfileOutDto: UserProfileOutDto;
}

export class UserMinOutDto extends UserCommonDto {
    public id: string;
    profileImageUrl: string;
}

export class UserUpdateInDto {
    username: string;
    firstName: string;
    lastName: string;
    contactNumber: string;
    email: string;
    userRole: string;
    enabled: boolean;
    userType: USER_TYPE;
}

export class UserProfileInDto {
    profileImageAttachmentId?: string;
    calendlyLink?: string;
}

export class UserProfileOutDto {
    profileImageOutDto: any; /* TODO: add type later  */
    calendlyLink?: string;
}

export class SignUpOutDto {
    userOutDto: UserOutDto;
    jwtTokenOutDto: JwtTokenDto;
}

export class LoginInDto {
    username: string;
    password: string;
}

export class SocialLoginInDto {
    idToken: string;
    emailAddress?: string;
    signInProvider?: SIGN_IN_PROVIDER;
}

export class LinkedInCredInDto {
    code?: string;
    clientId?: string;
    redirectUri?: string;
    clientSecret?: string;
    grantType?: string;
    provider?: SIGN_IN_PROVIDER;
}

export class LinkedInUserOutDto {
    linkedInUserInfoOutDto: LinkedInUserInfoOutDto;
    loginOutDto: LoginOutDto;
}

export class LinkedInUserInfoOutDto {
    email?: string;
    name?: string;
    picture?: string;
}

export class JwtTokenDto {
    accessToken: string;
    refreshToken: string;
}

export class LoginOutDto {
    jwtTokenDto: JwtTokenDto;
    userOutDto: UserOutDto;
    domainUserOutDto: DomainUserOutDto;
}

export class ForgotPasswordInDto {
    email: string;
}

export class UsernameInDto {
    email: string;
}

export class ForgotPasswordOutDto {
    email: string;
    username: string;
    otp: string;
}

export class DomainUserOutDto {
    employerMemberId: string;
    employerMemberType: EMPLOYER_MEMBER_TYPE;
    employerMemberStatus?: EMPLOYER_MEMBER_STATUS;
    contactPersonOutDto?: ContactPersonOutDto;
    employerMinOutDto?: EmployerMinOutDto;
}

export class EmployerMinOutDto {
    id: string;
    name: string;
    address: string;
    websiteLink: string;
    employerStatus: EMPLOYER_STATUS;
    companyOutDto: CompanyOutDto;
    enableMatchedCandidate: boolean;
    createdFrom: USER_TYPE;
}

export class ResetPasswordInDto {
    password: string;
}

export class EmailOutDto {
    to: string;
    status: number;
    responseBody: string;
}

export class VerifyOtpOutDto {
    email: string;
}

export enum USER_TYPE {
    CANDIDATE = 'CANDIDATE',
    EMPLOYER = 'EMPLOYER',
    UNIVERSITY = 'UNIVERSITY'
}

export enum EMPLOYER_MEMBER_STATUS {
    PENDING_REQUEST = 'PENDING_REQUEST ',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}

export enum EMPLOYER_MEMBER_TYPE {
    OWNER = 'OWNER',
    MEMBER = 'MEMBER',
    ADMIN = 'ADMIN'
}

export enum EMPLOYER_STATUS {
    WAITLIST = 'WAITLIST',
    INVITATION_SENT = 'INVITATION_SENT',
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED'
}

export enum SIGN_IN_PROVIDER {
    USERNAME_PASSWORD = 'USERNAME_PASSWORD',
    GOOGLE = 'GOOGLE',
    LINKEDIN = 'LINKEDIN'
}
