import 'firebase/database';
import {Injectable} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {AngularFireDatabase} from "@angular/fire/compat/database";

@Injectable({
    providedIn: 'root'
})
export class EdgeFirebaseApiService {
    private apiUrl = '/api_url';
    private standoutUrl = '/standout_url';
    private hireOSUrl = '/hireos_url';
    private organizaUrl = '/organiza_url';
    private loginLogoUrl = '/login_logo_url';
    private shortLogoUrl = '/short_logo_url';
    private longLogoUrl = '/long_logo_url';

    constructor(private firebaseDatabase: AngularFireDatabase) {
    }

    getApiUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.apiUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getStandoutUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.standoutUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getHireOSUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.hireOSUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getOrganizaUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.organizaUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getLoginLogoUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.loginLogoUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getShortLogoUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.shortLogoUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getLongLogoUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.longLogoUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }
}
